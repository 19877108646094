import React, {PureComponent} from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {Snackbar} from 'lib/react-md';
import _ from 'lib/lodash'

import {notificationActions} from 'components/handlers/actions'

import css from './NotificationContainer.module.css'

class NotificationContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.containerElement = document.createElement('div');
		document.body.appendChild(this.containerElement);
	}

	componentWillUnmount() {
		document.body.removeChild(this.containerElement);
	}

	render() {
		const {infoToasts, errorToasts, autohideToasts, dismissInfoToast, dismissErrorToast} = this.props;

		const container = (
			<div className={css['container']}>
				{_.isSet(infoToasts) && (
					<Snackbar
						className={css['info-toasts']}
						id="info-toasts"
						toasts={infoToasts}
						autohide={autohideToasts}
						onDismiss={dismissInfoToast}
					/>
				)}
				{_.isSet(errorToasts) && (
					<Snackbar
						className={css['error-toasts']}
						id="error-toasts"
						toasts={errorToasts}
						autohide={autohideToasts}
						onDismiss={dismissErrorToast}
					/>
				)}
			</div>
		);

		return ReactDOM.createPortal(container, this.containerElement);
	}
}

const mapStateToProps = ({notificationHandler}) => notificationHandler;

export default connect(mapStateToProps, notificationActions)(NotificationContainer);