import {fs} from './firebase'
import _ from 'lib/lodash'

const updateDocuments = (docRefList, batchSize = 500) => {
	if (_.isEmpty(docRefList)) return;

	_.map(_.chunk(docRefList, batchSize), chunk => {
		const batch = fs.batch();
		_.forEach(chunk, docRef => {
			batch.set(docRef.ref, docRef.fields, {merge: true})
		});
		return batch.commit();
	});

	return _.size(docRefList);
};

export const getUserRoles = user => {
	const {email = ""} = user;

	return fs.doc(`users/${email}`).get().then(userSnap => {
		if (!userSnap.exists) return Promise.reject(new Error("Access denied."));

		return Promise.resolve(userSnap.data());
	});
};

export const getProfileIds = () => fs.collection('profiles').get().then(profileSnap => _.map(profileSnap.docs, doc => doc.id));

export const getBundleProjects = profileId =>
	fs.collection(`profiles/${profileId}/bundles`).get().then(projectSnap => _.map(projectSnap.docs, doc => doc.id));

export const getStickerProjects = profileId =>
	fs.collection(`profiles/${profileId}/stickers`).get().then(projectSnap => _.map(projectSnap.docs, doc => doc.id));

export const getStickerTemplates = (profileId, stickerProjectId) =>
	fs.collection(`profiles/${profileId}/stickers/${stickerProjectId}/roles`).get().then(profileSnap => _.map(profileSnap.docs, doc => doc.id));

const createBundle = ({profileId, bundleProjectId, bundleId}) => {
	const updates = [];

	updates.push({
		ref: fs.doc(`bundles/${bundleId}`),
		fields: {
			profileRef: fs.doc(`profiles/${profileId}/bundles/${bundleProjectId}`),
			properties: {},
			status: "active"
		}
	});

	updates.push({ref: fs.doc(`bundles/${bundleId}/payloads/public`), fields: {}});
	updates.push({ref: fs.doc(`bundles/${bundleId}/payloads/shadow`), fields: {}});
	updates.push({ref: fs.doc(`bundles/${bundleId}/payloads/shared`), fields: {}});

	return updateDocuments(updates);
};

const createStickers = ({profileId, stickerProjectId, bundleId, stickers}) => {
	const updates = [];

	// create stickers
	stickers.forEach(({stickerId, template, tags}) => {
		updates.push({
			ref: fs.doc(`bundles/${bundleId}/stickers/${stickerId}`),
			fields: {
				profileRef: fs.doc(`profiles/${profileId}/stickers/${stickerProjectId}/roles/${template}`),
				properties: {},
				status: "active",
				tags
			}
		});

		updates.push({ref: fs.doc(`bundles/${bundleId}/payloads/${stickerId}`), fields: {}});
	});

	return updateDocuments(updates);
};


/* stickers: ['stickerId', 'stickerProfile'] */
export const createBundleAndStickers = async ({profileId, bundleProjectId, stickerProjectId, bundleId, stickers}) => {
	// create bundle
	await createBundle({profileId, bundleProjectId, bundleId});

	// create stickers
	await createStickers({profileId, stickerProjectId, bundleId, stickers});
};