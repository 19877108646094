import seedrandom from 'seedrandom';
import _ from 'lib/lodash'

// Overrides the following:
// https://github.com/facebook/react-devtools/blob/master/shells/chrome/src/checkForReact.js
export const enableReactDevTools = environments => {
	if (
		typeof window === 'object' &&
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
		Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers).length &&
		process.env.NODE_ENV === 'production' &&
		!environments.includes(process.env.REACT_APP_ENV)
	) {
		// do not load ReactDevTools
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers = {};
		return false;
	} else {
		return true;
	}
};

export const enableReduxDevTools = environments => (
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
	environments.includes(process.env.REACT_APP_ENV)
);

/* https://github.com/jbt/js-crypto/blob/master/sha1.js */
/*eslint-disable no-mixed-operators*/
export const hash = str1 => {
	var blockstart = 0,
		i = 0,
		W = [],
		A, B, C, D, F, G,
		H = [A = 0x67452301, B = 0xEFCDAB89, ~A, ~B, 0xC3D2E1F0],
		word_array = [],
		temp2,
		s = unescape(encodeURI(str1)),
		str_len = s.length;

	for (i = 0; i <= str_len; word_array[i >> 2] |= (s.charCodeAt(i) || 128) << (8 * (3 - i++ % 4))) {
	}

	word_array[temp2 = ((str_len + 8) >> 2) | 15] = str_len << 3;

	for (; blockstart <= temp2; blockstart += 16) {
		A = H;
		i = 0;

		for (; i < 80;
		       A = [[
			       (G = ((s = A[0]) << 5 | s >>> 27) + A[4] + (W[i] = (i < 16) ? ~~word_array[blockstart + i] : G << 1 | G >>> 31) + 1518500249) + ((B = A[1]) & (C = A[2]) | ~B & (D = A[3])),
			       F = G + (B ^ C ^ D) + 341275144,
			       G + (B & C | B & D | C & D) + 882459459,
			       F + 1535694389
		       ][0 | ((i++) / 20)] | 0, s, B << 30 | B >>> 2, C, D]
		) {
			G = W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16];
		}

		for (i = 5; i;) H[--i] = H[i] + A[i] | 0;
	}

	for (str1 = ''; i < 40;) str1 += (H[i >> 3] >> (7 - i++ % 8) * 4 & 15).toString(16);
	return str1;
};
/*eslint-enable no-mixed-operators*/

/*
sizeof.js
A function to calculate the approximate memory usage of objects
Created by Kate Morley - http://code.iamkate.com/ - and released under the terms
of the CC0 1.0 Universal legal code:
http://creativecommons.org/publicdomain/zero/1.0/legalcode
*/
export const sizeof = object => {

	// initialise the list of objects and size
	var objects = [object];
	var size = 0;

	// loop over the objects
	for (var index = 0; index < objects.length; index++) {
		// determine the type of the object
		switch (typeof objects[index]) {
			// the object is a boolean
			case 'boolean':
				size += 4;
				break;

			// the object is a number
			case 'number':
				size += 8;
				break;

			// the object is a string
			case 'string':
				size += 2 * objects[index].length;
				break;

			// the object is a generic object
			case 'object':
				var key;
				// if the object is not an array, add the sizes of the keys
				if (Object.prototype.toString.call(objects[index]) !== '[object Array]') {
					for (key in objects[index]) size += 2 * key.length;
				}
				// loop over the keys
				for (key in objects[index]) {
					// determine whether the value has already been processed
					var processed = false;
					for (var search = 0; search < objects.length; search++) {
						if (objects[search] === objects[index][key]) {
							processed = true;
							break;
						}
					}
					// queue the value to be processed if appropriate
					if (!processed) objects.push(objects[index][key]);
				}
				break;

			default:
				break;
		}
	}
	// return the calculated size
	return size;
};

export const generateTags = ({profileId, bundleId, stickerId, webLength, webCharset, salt}) => {
	const base = `${profileId}-${bundleId}-${stickerId}`;

	const seeds = {
		link: `${base}-link-${salt}`,
		key: `${base}-web-${salt}`,
		nfc: `${base}-nfc-${salt}`,
		qr: `${base}-qr-${salt}`,
	};

	const rng = seedrandom(seeds.key);

	// webLength-digit webcode
	const key = _.times(webLength, () => webCharset[Math.floor(rng() * webCharset.length)]).join('');

	// generate sha1-tags from keys:
	return {
		key,
		link: hash(seeds.link).substr(0, 24),
		web: hash(`${profileId}-${key}`).substr(0, 24),
		nfc: hash(seeds.nfc).substr(0, 24),
		qr: hash(seeds.qr).substr(0, 24),
	};
};

export const downloadFile = (filename, text) => {
	const element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
	element.setAttribute('download', filename);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
};