import React, {PureComponent} from 'react';
import {Redirect} from 'react-router';
import {connect} from 'react-redux'
import {Button} from 'lib/react-md'

import {authActions} from 'components/handlers/actions'

class LoginRoute extends PureComponent {
	render() {
		const {signIn, user} = this.props;

		return (
			<div>
				{!user && <Button flat primary onClick={signIn}>Sign In</Button>}
				{user && <Redirect to="/main"/>}
			</div>
		);
	}
}

const mapStateToProps = ({authHandler}) => ({...authHandler});

export default connect(mapStateToProps, authActions)(LoginRoute);