import React from 'react';
import {Provider} from 'react-redux'
import {Redirect, Route, Router, Switch} from 'react-router-dom'

import {store} from '../redux';
import history from '../history';
import {LoginRoute, MainRoute} from 'components/routes'
import {NotificationContainer, AuthContainer} from 'components/containers'
import {withAuth} from 'components/hocs'

const App = () => (
	<Provider store={store}>
		<AuthContainer>
			<Router history={history}>
				<Switch>
					<Route path="/" exact component={LoginRoute}/>
					<Route path="/main" component={withAuth(MainRoute)}/>
					<Redirect to="/"/>
				</Switch>
			</Router>
			<NotificationContainer/>
		</AuthContainer>
	</Provider>
);

export default App;