import React from 'react'
import ReactDOM from 'react-dom'
import WebFontLoader from 'webfontloader'
//import {whyDidYouUpdate} from 'why-did-you-update';

import {setLogging} from 'lib/logger'
import {enableReactDevTools} from 'lib/utils';
//import registerServiceWorker from 'services/registerServiceWorker'
import App from 'components/App'
import './index.css'

setLogging(); // disable console for production

if (process.env.NODE_ENV !== 'production') {
	//whyDidYouUpdate(React, { exclude: /^(Button|Toolbar|InkContainer|withInk|Portal|Paper|withTooltip|Tab|FontIcon|AccessibleFakeButton|Motion)/ });

	// enable React Dev Tools in Chrome for local, development and staging environments
	enableReactDevTools(['local', 'development', 'staging']);
}

// use custom (local) fonts to prevent glitches during load
WebFontLoader.load({
	custom: {
		families: ['Roboto:400,500,700', 'Material Icons']
	}
});

ReactDOM.render(<App/>, document.getElementById('root'));

//registerServiceWorker();