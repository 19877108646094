export function setLogging() {
	let enableLogging = true;

	switch (process.env.NODE_ENV) {
		case 'production':
			enableLogging = false;
			break;
		default:
	}

	if (!enableLogging) {
		const console = (window.console = window.console || {});

		const methods = [
			'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
			'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
			'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
			'timeStamp', 'trace', 'warn'
		];

		let length = methods.length;
		while (length--) console[methods[length]] = () => {};
	}

	console.log(`Running app in ${process.env.NODE_ENV} build / ${process.env.REACT_APP_ENV} mode`);
}