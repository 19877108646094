import {notificationActions} from 'components/handlers/actions'

export const infoNotificationMiddleware = store => next => action => {
	next(action);

	if (action.type.startsWith("NOTIFICATION")) return; // ignore notifications
	if (!action.type.endsWith("_INFO")) return; // only focus on info messages

	const {addInfoToast} = notificationActions;
	const {id = "", params = {}} = action.info;

	store.dispatch(addInfoToast(!!id ? {section: "info", id, params} : action.info));
};

export const errorNotificationMiddleware = store => next => action => {
	next(action);

	if (action.type.startsWith('NOTIFICATION')) return; // ignore notifications
	if (!action.type.endsWith('_ERROR')) return; // only focus on errors

	const {addErrorToast} = notificationActions;
	const {message = ""} = action.error || {};

	switch (message) {
		case "":
			return store.dispatch(addErrorToast("No error message given."));
		default:
			return store.dispatch(addErrorToast(message));
	}
};