import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from 'react-router';

export default WrappedComponent => {
	class Authentication extends Component {
		render() {
			const {user} = this.props;

			if (!user) return <Redirect to="/"/>;

			return <WrappedComponent {...this.props}/>;
		}
	}

	const mapStateToProps = ({authHandler}) => ({user: authHandler.user});

	return connect(mapStateToProps)(Authentication);
}