/* imports */
import {auth, db} from 'services'

/* action types */
export const types = {
	AUTH_INIT_OBSERVER: 'AUTH/INIT_OBSERVER',

	AUTH_USER_SIGNEDIN: 'AUTH/AUTH_USER_SIGNEDIN',
	AUTH_USER_SIGNEDOUT: 'AUTH/AUTH_USER_SIGNEDOUT',
	AUTH_USER_SIGNIN_ERROR: 'AUTH/AUTH_USER_SIGNIN_ERROR',
	AUTH_USER_ACCESS_ERROR: 'AUTH/AUTH_USER_ACCESS_ERROR',

	AUTH_SHOW_LOADING: 'AUTH/SHOW_LOADING',

	AUTH_RESET_STATE: 'AUTH/RESET_STATE'
};

/* initial state */
const initialState = {
	authObserver: null,
	user: null,
	roles: []
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.AUTH_INIT_OBSERVER:
			return {...state, authObserver: action.authObserver};

		case types.AUTH_USER_SIGNEDIN:
			return {...state, user: action.user, roles: action.roles, error: null};

		case types.AUTH_USER_SIGNEDOUT:
			return {...state, user: null, roles: [], error: null};

		case types.AUTH_USER_SIGNIN_ERROR:
			return {...state, user: null, roles: [], error: action.error};

		case types.AUTH_USER_ACCESS_ERROR:
			return {...state, user: null, roles: [], error: action.error};

		case types.AUTH_RESET_STATE:
			return {...initialState};

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	initAuthObserver: () => dispatch => {
		const authObserver = auth.lib.onIdTokenChanged(user => {
			if (user) { // User is signed in or token was refreshed.
				// check if user has access to the application
				db.getUserRoles(user).then(
					roles => {
						dispatch({type: types.AUTH_USER_SIGNEDIN, user, roles});
						dispatch({type: types.AUTH_INIT_OBSERVER, authObserver});
					},
					error => {
						dispatch({type: types.AUTH_USER_ACCESS_ERROR, error});
						dispatch({type: types.AUTH_INIT_OBSERVER, authObserver});
					}
				);
			} else {
				dispatch({type: types.AUTH_USER_SIGNEDOUT});
				dispatch({type: types.AUTH_INIT_OBSERVER, authObserver});
			}
		});
	},

	showAuthLoader: loader => ({type: types.AUTH_SHOW_LOADING, loader}),

	signIn: () => dispatch => auth.signIn().catch(error => dispatch({type: types.AUTH_USER_SIGNIN_ERROR, error})),

	signOut: () => dispatch => auth.signOut().catch(error => dispatch({type: types.AUTH_USER_SIGNIN_ERROR, error})),

	resetAuthHandler: () => ({type: types.AUTH_RESET_STATE})
};