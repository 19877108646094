import {auth} from './firebase';

export const signIn = () => {
	const provider = new auth.GoogleAuthProvider();

	return auth().signInWithPopup(provider)
	.catch(error => {
		//const {code="", message="", email="", credential=""} = error;
		const e = new Error(error.message);
		e.code = error.code; // Firebase or custom api error
		throw e;
	});
};

export const signedIn = () => auth().currentUser;

export const signOut = () => signedIn() ? auth().signOut() : Promise.resolve();

export const lib = auth();