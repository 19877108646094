import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import {enableReduxDevTools} from 'lib/utils';

import reducers from './reducers'
import middlewares from './middlewares'

const initialState = {
	/* provide internationalization options */
};

const composeEnhancers = enableReduxDevTools(['local', 'development', 'staging']) ?
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		maxAge: 100 // only keep the last 100 items
		// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	}) : compose;

const store = createStore(
	reducers,
	initialState,
	composeEnhancers(applyMiddleware(thunk, ...middlewares))
);

export default store;