// Firebase App is always required and must be first
// use @-libraries and ignore polyfill package; import polyfills via global service in index.html
import firebase from "@firebase/app";
// Add additional services you want to use
require("@firebase/auth");
require("@firebase/firestore");
require("@firebase/storage");

const config = {
	"apiKey": process.env.REACT_APP_FIREBASE_APIKEY,
	"authDomain": process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	"databaseURL": process.env.REACT_APP_FIREBASE_DATABASEURL,
	"projectId": process.env.REACT_APP_FIREBASE_PROJECTID,
	"storageBucket": process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	"messagingSenderId": process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
};

if (!firebase.apps.length) firebase.initializeApp(config);

export const auth = firebase.auth;
//export const functions = firebase.functions();

export const fs = firebase.firestore();
fs.settings({timestampsInSnapshots: true}); // needed as of v4.13.0

export let storage = null; // to be set by token info

export const setStorageBucket = bucketName => {
	if (bucketName) storage = firebase.app().storage(bucketName).ref();
};