import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Button, FontIcon, SelectField, TextField} from 'react-md';
import {db} from 'services'
import _ from 'lib/lodash'

import {generateTags, downloadFile} from 'lib/utils';
import {notificationActions} from 'components/handlers/actions';

import css from './NewBundlePage.module.css'

const salt = process.env.REACT_APP_HASH_SALT;

class NewBundlePage extends Component {
	static navItem = {
		key: 'add',
		primaryText: 'Add Bundle',
		leftIcon: <FontIcon>add</FontIcon>
	};

	state = {
		isSubmitting: false,
		profileIds: [],
		bundleProjectIds: [],
		stickerProjectIds: [],
		stickerTemplates: [],
		profileId: "",
		bundleProjectId: "",
		stickerProjectId: "",
		bundleId: "",
		masterStickerId: "M0001",
		masterStickerTemplate: "",
		slaveStickerTemplate: "",
		slaveStickerCount: 0,
		slaveStickerPattern: "00000",
		slaveStickerCharset: "ABCDEFGHIJKLMNPQRSTUVWXYZ",
		slaveStickerStartId: 0,
		slaveStickerWebLength: 9,
	};

	async componentDidMount() {
		const profileIds = await db.getProfileIds();

		this.setState({profileIds: ["", ...profileIds]});
	}

	// eslint-disable-next-line
	async componentDidUpdate(prevProps, prevState) {
		if (prevState.profileId !== this.state.profileId) {
			const bundleProjectList = !!this.state.profileId ? await db.getBundleProjects(this.state.profileId) : [];
			const stickerProjectList = !!this.state.profileId ? await db.getStickerProjects(this.state.profileId) : [];

			this.setState({
				bundleProjectIds: ["", ...bundleProjectList],
				stickerProjectIds: ["", ...stickerProjectList]
			});
		}
		if (prevState.stickerProjectId !== this.state.stickerProjectId) {
			const stickerTemplates = !!this.state.stickerProjectId ? await db.getStickerTemplates(this.state.profileId, this.state.stickerProjectId) : [];

			this.setState({stickerTemplates: ["", ...stickerTemplates]});
		}
	}

	validateForm = () => {
		const {
			profileId,
			bundleProjectId,
			stickerProjectId,
			bundleId,
			masterStickerId, masterStickerTemplate,
			slaveStickerCount, slaveStickerPattern, slaveStickerCharset, slaveStickerWebLength, slaveStickerStartId, slaveStickerTemplate,
		} = this.state;

		if (!profileId) return "Bitte wählen Sie ein Bundle Profil aus.";
		if (!bundleProjectId) return "Bitte wählen Sie ein Bundle Projekt aus.";
		if (!bundleId) return "Bitte geben Sie eine Bundle-Id an (zB 180901).";
		if (!stickerProjectId) return "Bitte wählen Sie ein Sticker Projekt aus.";
		if (!masterStickerTemplate) return "Bitte geben Sie das Profil-Template des Master-Stickers an (zB. mailing-18-master).";
		if (!masterStickerId) return "Bitte geben Sie die Seriennummer des Master-Stickers an (zB. M0001).";
		if (!slaveStickerTemplate) return "Bitte geben Sie das Profil-Template der Slave-Sticker an (zB. mailing-18-slave).";
		if (!slaveStickerCount) return "Bitte geben Sie an, wie viele Slave Sticker erzeugt werden sollen (zB. 100).";
		if (!slaveStickerPattern) return "Bitte geben Sie ein Muster für die Slave-Sticker Seriennummer an (zB. 000000).";
		if (!slaveStickerCharset) return "Bitte geben Sie ein Character-Set für die Slave-Sticker an (zB. ABCDEFGHIJK...).";
		if (!slaveStickerWebLength) return "Bitte geben Sie an, wie viele Zeichen die Webabrufcodes haben sollen (zB. 9).";
		if (!slaveStickerStartId) return "Bitte geben Sie die Seriennummer an, mit der gestartet werden soll (zB. 1)";

		return false;
	};

	createBundle = async () => {
		const {addInfoToast, addErrorToast} = this.props;
		const {
			profileId,
			bundleProjectId,
			stickerProjectId,
			bundleId,
			masterStickerId, masterStickerTemplate,
			slaveStickerCount, slaveStickerPattern, slaveStickerCharset, slaveStickerWebLength, slaveStickerStartId, slaveStickerTemplate,
		} = this.state;

		const validationError = this.validateForm();
		if (validationError) return addErrorToast(validationError);

		const webCharset = slaveStickerCharset;
		const webLength = slaveStickerWebLength;

		const match = /[0]{1,}/.exec(slaveStickerPattern);

		if (match) {
			this.setState({isSubmitting: true});

			const padLength = match[0].length;
			const startString = match.index > 0 ? slaveStickerPattern.substr(0, match.index) : "";
			const endString = slaveStickerPattern.substr(match.index + padLength);

			const slaveStickerIds = _.times(slaveStickerCount, i => {
				const serial = (slaveStickerStartId + i).toString().padStart(padLength, "0");
				return startString + serial + endString;
			});

			const stickers = [];
			stickers.push({
				profileId,
				stickerProjectId,
				stickerId: masterStickerId,
				template: masterStickerTemplate,
				tags: generateTags({profileId, bundleId, stickerId: masterStickerId, webLength, webCharset, salt})

			});
			_.forEach(slaveStickerIds, slaveStickerId => stickers.push({
				profileId,
				stickerId: slaveStickerId,
				template: slaveStickerTemplate,
				tags: generateTags({profileId, bundleId, stickerId: slaveStickerId, webLength, webCharset, salt})
			}));

			await db.createBundleAndStickers({profileId, bundleProjectId, stickerProjectId, bundleId, stickers});

			const s = stickers.reduce((res, x) => res + `${bundleId}\t${x.stickerId}\t${x.tags.key}\t${x.tags.nfc}\t${x.tags.qr}\t${x.template}\n`, "bundleno\tserialno\twebcode\tnfccode\tqrcode\ttemplate\n");
			downloadFile(`${profileId}_${bundleId}.txt`, s);

			this.setState({isSubmitting: false});

			addInfoToast("Bundle erfolgreich erzeugt.");
		}
	};

	render() {
		const {
			profileIds, bundleProjectIds, stickerProjectIds, stickerTemplates, slaveStickerCharset,
			slaveStickerWebLength, isSubmitting
		} = this.state;

		return (
			<div className={css.page}>
				<SelectField
					id="select-bundle-profiles"
					label="Bundle-Profil"
					placeholder="Bitte Bundle-Profil auswählen"
					defaultValue=""
					menuItems={profileIds}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={true}
					onChange={value => this.setState({profileId: value})}
					fullWidth
				/>
				<SelectField
					id="select-bundle-project"
					label="Bundle-Projekt"
					placeholder="Bitte Bundle-Projekt auswählen"
					defaultValue=""
					menuItems={bundleProjectIds}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={true}
					onChange={value => this.setState({bundleProjectId: value})}
					fullWidth
				/>
				<TextField
					id="bundleId"
					className="md-cell"
					label="Bundle Id"
					onChange={value => this.setState({bundleId: value})}
				/>
				<SelectField
					id="select-sticker-project"
					label="Sticker-Projekt"
					placeholder="Bitte Sticker-Projekt auswählen"
					defaultValue=""
					menuItems={stickerProjectIds}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={true}
					onChange={value => this.setState({stickerProjectId: value})}
					fullWidth
				/>
				<SelectField
					id="select-master-template"
					label="Master-Template"
					placeholder="Bitte Master-Template auswählen"
					defaultValue=""
					menuItems={stickerTemplates}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={true}
					onChange={value => this.setState({masterStickerTemplate: value})}
					fullWidth
				/>
				<SelectField
					id="select-slave-template"
					label="Slave-Template"
					placeholder="Bitte Slave-Template auswählen"
					defaultValue=""
					menuItems={stickerTemplates}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={true}
					onChange={value => this.setState({slaveStickerTemplate: value})}
					fullWidth
				/>
				<TextField
					id="master-sticker-id"
					className="md-cell"
					label="Master Sticker Seriennummer"
					onChange={value => this.setState({masterStickerId: value})}
				/>
				<TextField
					id="slave-count"
					className="md-cell"
					type="number"
					label="Anzahl Slave Sticker"
					onChange={value => this.setState({slaveStickerCount: value})}
				/>
				<TextField
					id="slave-serial-charset"
					className="md-cell"
					defaultValue={slaveStickerCharset}
					label="Slave Sticker Character-set"
					onChange={value => this.setState({slaveStickerCharset: value})}
				/>
				<TextField
					id="slave-weblength"
					className="md-cell"
					type="number"
					label="Stellen des Webcodes"
					defaultValue={slaveStickerWebLength}
					onChange={value => this.setState({slaveStickerWebLength: value})}
				/>
				<TextField
					id="slave-serial-digits"
					className="md-cell"
					label="Slave Sticker Seriennummern-Muster (zB S0000)"
					onChange={value => this.setState({slaveStickerPattern: value})}
				/>
				<TextField
					id="slave-serial-digits"
					className="md-cell"
					type="number"
					label="Slave Sticker Start-Seriennummer"
					onChange={value => this.setState({slaveStickerStartId: parseInt(value)})}
				/>
				{isSubmitting && "Bitte warten..."}
				{!isSubmitting && <Button flat primary onClick={this.createBundle}>Erzeuge Bundle</Button>}
			</div>
		);
	}
}

export default connect(null, notificationActions)(NewBundlePage);