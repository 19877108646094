import React, {Component} from "react";
import {connect} from 'react-redux';
import {NavigationDrawer, FontIcon} from "react-md"
import _ from "lib/lodash"

import {authActions} from 'components/handlers/actions'
import {NewBundlePage, NewStickersPage} from "components/pages"

import css from './MainRoute.module.css'

const Pages = [NewBundlePage, NewStickersPage];

const copyToClipboard = str => {
	const el = document.createElement('textarea');
	el.value = str;
	el.setAttribute('readonly', '');
	el.style = {position: 'absolute', left: '-9999px'};
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};

class MainRoute extends Component {
	state = {
		Page: Pages[0]
	};

	setPage = Page => () => this.setState({Page});

	render() {
		const navItems = _.map(Pages, (Page, idx) => ({
			...Page.navItem,
			active: idx === 0,
			onClick: this.setPage(Page)
		}));

		navItems.push({key: "divider1", divider: true});

		navItems.push({
			key: "copytoken",
			primaryText: "Copy Token to Clipboard",
			leftIcon: <FontIcon>security</FontIcon>,
			onClick: async () => {
				const token = await this.props.user.getIdToken();
				copyToClipboard(token)
			}
		});

		navItems.push({key: "divider2", divider: true});

		navItems.push({
			key: "signout",
			primaryText: "Sign out",
			leftIcon: <FontIcon>highlight_off</FontIcon>,
			onClick: authActions.signOut()
		});

		const {Page} = this.state;

		return (
			<NavigationDrawer
				contentClassName={css.container}
				navItems={navItems}
				mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY_MINI}
				tabletDrawerType={NavigationDrawer.DrawerTypes.FULL_HEIGHT}
				desktopDrawerType={NavigationDrawer.DrawerTypes.FULL_HEIGHT}
				toolbarTitle="Sensotix Smart Stickers"
				toolbarActions={null}
				contentId="main-demo-content"
				drawerTitle="Admin Portal"
			>
				<Page/>
			</NavigationDrawer>
		);
	}
}

const mapStateToProps = ({authHandler}) => authHandler;

export default connect(mapStateToProps, authActions)(MainRoute);
