import React, {Component} from 'react';
import {FontIcon} from 'react-md';

class NewStickersPage extends Component {
	static navItem = {
		key: 'playlist_add',
		primaryText: 'Add Stickers',
		leftIcon: <FontIcon>playlist_add</FontIcon>
	};

	render() {
		return (
			<div>
				New Bundle Stickers
			</div>
		);
	}
}

export default NewStickersPage;