import {PureComponent} from 'react';
import {connect} from 'react-redux'

import {authActions} from 'components/handlers/actions'

class AuthContainer extends PureComponent {
	componentDidMount() {
		this.props.initAuthObserver();
	}

	componentWillUnmount() {
		const {authObserver} = this.props;

		!!authObserver && authObserver(); // release firebase.auth observer
	}

	render() {
		const {authObserver, children} = this.props;

		return authObserver && children;
	}
}

const mapStateToProps = ({authHandler}) => ({
	authObserver: authHandler.authObserver,
	user: authHandler.user
});

export default connect(mapStateToProps, authActions)(AuthContainer);
