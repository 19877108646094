import chunk from 'lodash/chunk'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import findLast from 'lodash/findLast'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import memoize from 'lodash/memoize'
import mergeWith from 'lodash/mergeWith'
import noop from 'lodash/noop'
import pickBy from 'lodash/pickBy'
import reduce from 'lodash/reduce'
import set from 'lodash/set'
import size from 'lodash/size'
import template from 'lodash/template'
import templateSettings from 'lodash/templateSettings'
import times from 'lodash/times'
import transform from 'lodash/transform'
import uniqueId from 'lodash/uniqueId'

const isSet = o => !isEmpty(o);

const deepEqual = (a, b, property) => isEqual(get(a, property), get(b, property));

const anyDiff = (a, b, properties) => !reduce(properties, (result, property) => result && deepEqual(a, b, property), true);

const printDiffList = (oldObj, newObj) => {
	const compareObj = cloneDeep(oldObj);
	mergeWith(compareObj, newObj, (objectValue, sourceValue, key) => {
		if (!(isEqual(objectValue, sourceValue)) && (Object(objectValue) !== objectValue)) {
			console.log(`${key}\n  Expected: ${sourceValue}\n  Actual:  ${objectValue}`);
		}
	});
};

export default {
	chunk,
	filter,
	findLast,
	forEach,
	get,
	has,
	includes,
	isEmpty,
	isEqual,
	map,
	memoize,
	noop,
	pickBy,
	reduce,
	set,
	size,
	template,
	templateSettings,
	times,
	transform,
	uniqueId,

	isSet,
	deepEqual,
	anyDiff,
	cloneDeep,
	mergeWith,
	printDiffList
}